import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import WebriQForm from '@webriq/gatsby-webriq-form'

class EvaluationPage extends React.Component {

  render() {
    return (
      <Layout head={{ title: `What's Your Home Really Worth?` }}>
        <CommonHero pageTitle="What's Your Home Really Worth?" />
        <main className="bg-white">
          <div className="contact-section py-5">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <p className="text-center">
                      Simply fill out this form to receive your free home
                      evaluation. Our experienced real estate team will review
                      your home's details, and provide you with an up-to-date
                      Comparable Market Analysis.
                    </p>
                    <div className="mt-5">
                      <WebriQForm
                        name="Home Evaluation Form"
                        data-form-id="5c986f5f2323370a34c0888e"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Fist Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Last Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="Email"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Phone (optional)</label>
                              <input
                                type="tel"
                                className="form-control"
                                name="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Address"
                          />
                        </div>
                        <div className="text-center">
                          <div className="webriq-recaptcha mb-4 d-flex justify-content-center" />
                          <button className="alink hvr-shadow" type="submit">
                            Send Message
                          </button>
                        </div>
                      </WebriQForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default EvaluationPage
